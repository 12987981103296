import { useCallback, useEffect, useMemo, useRef } from 'react'
import { includes } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { DOCUMENT_DIRECTIVE_NAME } from 'src/constants/directiveNames'
import useSelector from 'src/hooks/common/useSelector'
import useDispatch from 'src/hooks/common/useDispatch'
import useApp from 'src/hooks/app/useApp'
import { actions as stepActions } from 'src/state/case/slice'
import { getStepList } from 'src/state/case/actions'
import { RequestedInfos, TDocumentStepInfo, TStepObj } from 'src/typings/types'
import { useEnvelopesStatusQuery } from 'src/state/rtkQueries/docuSignRequest'

interface UseStepsReturn {
  stepObj: TStepObj
  isSingpassFlow: boolean
  isFetchStepListError?: boolean
  fetchStepListError?: Error
  isFetchStepListLoading?: boolean
  isUploadingDetails?: boolean
  stepListFetched?: boolean
  backBtnClickedTime: number
  finishStep: (stepName: string) => void
  declineStep: (stepName: string) => void
  hasFinishedStep: (stepName: string) => boolean
  hasDeclinedStep: (stepName: string) => boolean
  getSteps: () => void
  updateIsUploadingDetails: (isUploading?: boolean) => void
  updateBackButtonClickedTimestamp: () => void
  updateDocuSignEnvelopesStatus: (envelopesStatus: { [key: string]: string }) => void
}

export default function useSteps(): UseStepsReturn {
  const dispatch = useDispatch()
  const { isEntity } = useApp()
  const [searchParams] = useSearchParams()
  const isValidation = useMemo(() => Boolean(searchParams.get('validation')), [searchParams])
  const isTodo = useMemo(() => searchParams.get('step')?.toLowerCase() === 'todo', [searchParams])
  const finishedSteps = useSelector((state) => state.case.finishedSteps)
  const declinedSteps = useSelector((state) => state.case.declinedSteps)
  const isFetchStepListError = useSelector((state) => state.case.isFetchStepListError)
  const fetchStepListError = useSelector((state) => state.case.fetchStepListError)
  const stepListFetched = useSelector((state) => state.case.stepListFetched)
  const isFetchStepListLoading = useSelector((state) => state.case.isFetchStepListLoading)
  const isUploadingDetails = useSelector((state) => state.case.isUploadingDetails)
  const stepObj = useSelector((state) => state.case.stepObj)
  const backBtnClickedTime = useSelector((state) => state.case.backBtnClickedTime)
  const refetchEnvelopesStatusRef = useRef<typeof refetchEnvelopesStatus>()

  const docuSignEnvelopIds = useMemo(() => {
    return stepObj[DOCUMENT_DIRECTIVE_NAME]?.documentInfos
      ?.filter((infos) => infos.docuSignEnvelopId)
      .map((doc) => doc.docuSignEnvelopId)
  }, [stepObj])

  const {
    data: envelopesStatus,
    refetch: refetchEnvelopesStatus,
    isUninitialized
  } = useEnvelopesStatusQuery(docuSignEnvelopIds as string[], {
    skip: !docuSignEnvelopIds?.length
  })

  const isAllDocuSignEnvelopesSigned = useMemo(() => {
    if (!envelopesStatus) {
      return false
    }
    return Object.values(envelopesStatus).every((status) => status === 'completed')
  }, [envelopesStatus])

  useEffect(() => {
    refetchEnvelopesStatusRef.current = refetchEnvelopesStatus
  }, [refetchEnvelopesStatus])

  useEffect(() => {
    if (isTodo && !isAllDocuSignEnvelopesSigned && !isUninitialized) {
      refetchEnvelopesStatusRef.current?.()
    }
  }, [isValidation, refetchEnvelopesStatus, isUninitialized, isTodo, isAllDocuSignEnvelopesSigned])

  const updateDocuSignEnvelopesStatus = useCallback(
    (envelopesStatus: { [key: string]: string }) => {
      dispatch(stepActions.updateDocuSignEnvelopesStatus(envelopesStatus))
    },
    [dispatch]
  )

  useEffect(() => {
    if (envelopesStatus) {
      updateDocuSignEnvelopesStatus(envelopesStatus)
    }
  }, [envelopesStatus, updateDocuSignEnvelopesStatus])

  const finishStep = useCallback(
    (stepName: string) => {
      dispatch(stepActions.finishStep(stepName))
    },
    [dispatch]
  )
  const declineStep = useCallback(
    (stepName: string) => {
      dispatch(stepActions.declineStep(stepName))
    },
    [dispatch]
  )
  const hasFinishedStep = useCallback(
    (stepName: string) => {
      return includes(finishedSteps, stepName)
    },
    [finishedSteps]
  )
  const hasDeclinedStep = useCallback(
    (stepName: string) => {
      return includes(declinedSteps, stepName)
    },
    [declinedSteps]
  )
  const getSteps = useCallback(() => {
    !isEntity && dispatch(getStepList())
  }, [dispatch, isEntity])

  const updateIsUploadingDetails = useCallback(
    (isUploading?: boolean) => {
      dispatch(stepActions.updateIsUploadingDetails(isUploading))
    },
    [dispatch]
  )

  const updateBackButtonClickedTimestamp = useCallback(() => {
    dispatch(stepActions.updateBackButtonClickedTimestamp())
  }, [dispatch])

  const isSingpassFlow = useMemo(() => {
    const docStep = stepObj[DOCUMENT_DIRECTIVE_NAME] as TDocumentStepInfo
    if (docStep) {
      const { requestedInfos = [] } = docStep
      if (requestedInfos.includes(RequestedInfos.MyInfo)) {
        return true
      }
    }
    return false
  }, [stepObj])

  return {
    stepObj,
    isSingpassFlow,
    isFetchStepListError,
    fetchStepListError,
    isFetchStepListLoading,
    isUploadingDetails,
    stepListFetched,
    backBtnClickedTime,
    finishStep,
    declineStep,
    hasFinishedStep,
    hasDeclinedStep,
    getSteps,
    updateIsUploadingDetails,
    updateBackButtonClickedTimestamp,
    updateDocuSignEnvelopesStatus
  }
}
