import { merge } from 'lodash'
import { createTheme, uploadPortalTheme as defaultTheme } from '@kyc-ui/react'
import { enUS, Localization } from '@mui/material/locale'
import { ReactComponent as LogoWithText } from 'src/assets/images/KYC_Screen Logo_2_Colour.svg'
import { ReactComponent as Location } from 'src/assets/images/home-location.svg'
import { ReactComponent as LogoIcon } from 'src/assets/images/symbol-purple.svg'
import { ReactComponent as Agreement } from 'src/assets/images/agreement.svg'
import Login from 'src/assets/images/login-img.svg'
import { ReactComponent as Completed } from 'src/assets/images/completed.svg'
import { ReactComponent as EntityBeforeWeBegin } from 'src/assets/images/entity-before-we-begin.svg'
import { ReactComponent as EntityControllingBodies } from 'src/assets/images/entity-controlling-bodies.svg'
import { ReactComponent as EntityOwnership } from 'src/assets/images/entity-ownership.svg'
import { ReactComponent as EntityPsc } from 'src/assets/images/entity-psc.svg'
import { ReactComponent as DocusignImportantInstructions } from 'src/assets/images/docusign-important-instructions.svg'
import uploadAnimation from 'src/assets/lottie-files/upload.json'
import checkAnimation from 'src/assets/lottie-files/check.json'
import pinAnimation from 'src/assets/lottie-files/pin.json'
import emptyMemberList from 'src/assets/lottie-files/empty-member-list.json'
import { ReactComponent as PageNotFound } from 'src/assets/images/page-not-found.svg'
import { ReactComponent as Uploaded } from 'src/assets/images/entity-uploaded.svg'
import Loading from 'src/assets/images/loading.gif'
import AcceptedDocumentsImg from 'src/theming/kyc/lotties/accepted-documents.json'
import SelfieImg from 'src/theming/kyc/lotties/selfie.json'
import POIImg from 'src/theming/kyc/lotties/poi-accepted-doc.json'

export const theme = (brand: string, language: Localization = enUS) => {
  let brandTheme = {}
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    brandTheme = require(`src/theming/${brand}`).default
    // eslint-disable-next-line no-empty
  } catch (err) {}
  return createTheme(
    defaultTheme,
    merge(
      {
        palette: {
          mode: 'light'
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: 'none !important',
                border: 'none'
              }
            }
          },
          MuiButtonBase: {
            styleOverrides: {
              root: {
                '&.MuiListItemButton-root': {
                  border: '1px solid #ffffff',
                  boxShadow: 'none'
                },
                '&.MuiListItemButton-root.Mui-selected': {
                  border: '1px solid #B693F9'
                },
                '&.MuiListItemButton-root:hover': {
                  border: '1px solid #ffffff'
                }
              }
            }
          },
          MuiUseMediaQuery: {
            defaultProps: {
              noSsr: true
            }
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                color: '#64748B',
                background: '#ffffff'
              }
            }
          },
          MuiStack: {
            styleOverrides: {
              root: {
                position: 'unset !important'
              }
            }
          },
          MuiSnackbar: {
            styleOverrides: {
              root: {
                position: 'fixed !important'
              }
            }
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                pointerEvents: 'auto'
              }
            }
          },
          MuiLinearProgress: {
            styleOverrides: {
              root: {
                backgroundColor: '#C5A9FA',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#6D27F3'
                }
              }
            }
          },
          MuiFormLabel: {
            styleOverrides: {
              root: ({ ownerState }: { ownerState: { color: string } }) => ({
                ...(ownerState.color === 'error' && {
                  color: defaultTheme.palette.error.main
                })
              })
            }
          }
        },
        images: {
          upload: JSON.stringify(uploadAnimation),
          check: JSON.stringify(checkAnimation),
          pin: JSON.stringify(pinAnimation),
          emptyMemberList: JSON.stringify(emptyMemberList),
          logo: LogoWithText,
          logoIcon: LogoIcon,
          location: Location,
          login: Login,
          completed: Completed,
          agreement: Agreement,
          docusignImportantInstructions: DocusignImportantInstructions,
          individualBeforeWeBegin: EntityBeforeWeBegin,
          entityBeforeWeBegin: EntityBeforeWeBegin,
          entityControllingBodies: EntityControllingBodies,
          entityOwnership: EntityOwnership,
          entityPsc: EntityPsc,
          notFound: PageNotFound,
          uploadedIcon: Uploaded,
          loading: Loading,
          acceptedDocumentsImg: JSON.stringify(AcceptedDocumentsImg),
          selfieImg: JSON.stringify(SelfieImg),
          poiImg: JSON.stringify(POIImg)
        },
        colorName: {
          button: 'primary',
          linearProgress: 'primary',
          checkbox: 'secondary',
          stepColor: 'primary.main',
          cardBorderColor: 'transparent',
          entityButton: 'primary',
          addButton: 'secondary'
        },
        colorString: {},
        others: {
          loginBackgroundSize: 'contain'
        }
      },
      brandTheme
    ),
    {
      components: {
        MuiPaper: {
          styleOverrides: {
            elevation0: {
              boxShadow: 'none'
            }
          }
        }
      }
    },
    language
  )
}
