import { HubConnectionBuilder } from '@microsoft/signalr'
import { BroadcastChannel } from 'broadcast-channel'
import storage from 'src/utils/storage'
import type { TSignalRNotification, TValidationResult } from 'src/typings/types'

const signalrConnect = async (signalToken: string) => {
  const connection = new HubConnectionBuilder()
    .withUrl(`${storage.getSignalrServerUrl()}/kyc-hub?access_token=${signalToken}`, {
      withCredentials: false,
      accessTokenFactory: () => signalToken
    })
    .withAutomaticReconnect()
    .build()

  await connection.start()
  const channel = new BroadcastChannel('signalRNotifySuccess')
  connection.on('notifySuccess', (type, title, info: string) => {
    if (title != null) {
      // eslint-disable-next-line no-console
      console.log('Undefined signalR notification.')
      // eslint-disable-next-line no-console
      console.log(`Title: ${title}`)
      // eslint-disable-next-line no-console
      console.log(`Type: ${type}`)
      // eslint-disable-next-line no-console
      console.log(`Info: ${info}`)
      return
    }

    const validationResult = JSON.parse(info) as TValidationResult
    channel.postMessage({
      fileCat: type,
      validationResult
    } as TSignalRNotification)
  })
  connection.on('envelopeCompleted', (params) => {
    /**
    {
      "envelopeId": "3302ed99-5e42-4509-9b10-8a9e6677f029",
      "generatedDateTime": "2024-11-20T09:17:02.2549565Z",
      "success": true,
      "warningKeys": [],
      "errors": []
    }
    */
    channel.postMessage({
      type: 'envelopeCompleted',
      status: {
        [params.envelopeId]: 'completed'
      }
    })
  })
  const errorChannel = new BroadcastChannel('signalRNotifyError')
  connection.on('notifyError', (type, title, info: string) => {
    if (title != null) {
      // eslint-disable-next-line no-console
      console.log('Undefined signalR notification.')
      // eslint-disable-next-line no-console
      console.log(`Title: ${title}`)
      // eslint-disable-next-line no-console
      console.log(`Type: ${type}`)
      // eslint-disable-next-line no-console
      console.log(`Info: ${info}`)
      return
    }

    const validationResult = JSON.parse(info) as TValidationResult
    errorChannel.postMessage({
      fileCat: type,
      validationResult
    } as TSignalRNotification)
  })

  return signalToken
}

export default signalrConnect
