import { ENTITY_UPLOAD_FIELD_NAMES } from 'src/constants/entity'
import { EMemberType, ISearchItem, TEntityStep } from 'src/typings/entity'
import { DropDownOption, TEntityForm } from 'src/typings/types'
import { ICountry } from 'src/typings/country'
import storage from 'src/utils/storage'
import * as EntityUploadPortalService from 'src/services/EntityUploadPortalService'
import { checkIsCompanyTypeVisible, hasCountryCaseType, COUNTRY_CASE_TYPE } from 'src/utils/checkIsCompanyTypeVisible'

export type TStepStatus = Pick<
  TEntityStep,
  | 'isNotAvailable'
  | 'hasRegisterNumber'
  | 'is4UnregisteredEntity'
  | 'isManualIntervention'
  | 'isLinkedCaseClosed'
  | 'isReadOnlyMode'
>
export type TEntityCaseStatus = {
  searchButtonVisible: boolean
  companyType: boolean
  dataSource: boolean
  companyStatusVisible: boolean
  isManualCase: boolean
  isSwitchEnable: boolean
  isForceOn: boolean
  isEntity: boolean
  isOpenCorporatesOn: boolean
  isEmptyCase?: boolean
  canScrap?: boolean
}

export default class CreateEntityCaseService {
  static getDataSourcesOptions(values: TEntityForm | string): DropDownOption[] {
    if (typeof values === 'string') {
      return storage.getDropDownOptionsByKey(
        EntityUploadPortalService.getDropDownOptionKey(ENTITY_UPLOAD_FIELD_NAMES.DataSource, values)
      )
    }

    const jurisdictionId = values?.properties?.[ENTITY_UPLOAD_FIELD_NAMES.Jurisdiction]
    if (jurisdictionId) {
      return storage.getDropDownOptionsByKey(
        EntityUploadPortalService.getDropDownOptionKey(ENTITY_UPLOAD_FIELD_NAMES.DataSource, jurisdictionId)
      )
    }
    return []
  }

  static getIsOpenCorporatesOn(kind?: number): boolean {
    return typeof kind === 'number' && hasCountryCaseType(kind, COUNTRY_CASE_TYPE.OpenCorporate)
  }

  static getDropDropOptions(fieldName?: string, id?: number | string): DropDownOption[] {
    return storage.getDropDownOptionsByKey(EntityUploadPortalService.getDropDownOptionKey(fieldName, id))
  }

  static getCompanyTypes(): DropDownOption[] {
    return this.getDropDropOptions('CompanyTypes')
  }

  static getCompanyStatuses(): DropDownOption[] {
    return this.getDropDropOptions('CompanyStatuses')
  }

  static getJurisdictionsList(): ICountry[] {
    return this.getDropDropOptions('jurisdictionsList') as unknown as ICountry[]
  }

  static getNationalitiesList(): ICountry[] {
    return this.getDropDropOptions('nationalitiesList') as unknown as ICountry[]
  }

  static checkIsEmptyCase(options: { kind?: number; manualCase?: boolean; entity?: ISearchItem }): boolean {
    const { kind, manualCase, entity } = options
    return (
      manualCase ||
      typeof kind === 'undefined' ||
      (!hasCountryCaseType(kind, COUNTRY_CASE_TYPE.ScrapAutomated) &&
        !hasCountryCaseType(kind, COUNTRY_CASE_TYPE.HasSpecification)) ||
      (!!entity && !entity.code && !entity.type)
    )
  }

  static checkEntityCaseStatus({
    values,
    memberType,
    searchItem,
    isNew = false,
    is4UnregisteredEntity = false,
    stepId = 0
  }: {
    values: TEntityForm
    memberType: EMemberType
    searchItem?: ISearchItem
    isNew?: boolean
    is4UnregisteredEntity?: boolean
    stepId?: number
  }): TEntityCaseStatus {
    const jurisdictionsList = this.getJurisdictionsList()
    const entityCaseStatus: TEntityCaseStatus = {
      searchButtonVisible: false,
      companyType: false,
      dataSource: false,
      // KYC.WebApp/Templates/CaseDetail/CompanyProperties.html L212
      // (IsManualIntervention || !companyDetails.CanScrap || companyDetails.Is4UnregisteredEntity) &&
      // property.FieldName == 'CompanyStatus' || (property.FieldName == 'CompanyStatus' && property.IsMandatory)
      companyStatusVisible: false,
      isManualCase: false,
      isSwitchEnable: false,
      isForceOn: false,
      isEntity: false,
      isOpenCorporatesOn: false,
      isEmptyCase: false,
      canScrap: false
    }

    if (memberType !== EMemberType.entity && memberType !== EMemberType.identity) {
      return entityCaseStatus
    }

    entityCaseStatus.isEntity = true

    const isManualCase =
      (values?.properties?.[ENTITY_UPLOAD_FIELD_NAMES.UnregisteredEntity] === 'True' || is4UnregisteredEntity) &&
      !!values?.properties?.[ENTITY_UPLOAD_FIELD_NAMES.Jurisdiction]
    entityCaseStatus.isManualCase = isManualCase
    const jurisdictionId = values?.properties?.[ENTITY_UPLOAD_FIELD_NAMES.Jurisdiction]
    const dataSource = values?.properties?.[ENTITY_UPLOAD_FIELD_NAMES.DataSource]

    // KnowYourCustomer/kyc_webapp_2/KYC.WebApp/Scripts/Custom/CaseDetail/companyproperties.directive.js
    // KnowYourCustomer/kyc_webapp_2/KYC.WebApp/Templates/CaseDetail/CompanyProperties.html
    const selectedJurisdiction = jurisdictionsList?.find((jurisdiction) => String(jurisdiction.id) === jurisdictionId)
    const selectedDataSource = selectedJurisdiction?.sources?.find((source) => String(source.name) === dataSource)
    const kind = selectedDataSource?.kind ?? selectedJurisdiction?.kind
    if (jurisdictionId) {
      entityCaseStatus.companyStatusVisible = Boolean(
        typeof kind !== 'undefined' && kind & COUNTRY_CASE_TYPE.ScrapAutomated
      )
    } else {
      entityCaseStatus.companyStatusVisible = false
    }

    if (jurisdictionId) {
      if (typeof kind !== 'undefined') {
        const dataSources = this.getDataSourcesOptions(jurisdictionId)
        entityCaseStatus.dataSource = Boolean(dataSources && dataSources.length > 1)
        // KYC.WebApp/Templates/CaseDetail/CompanyProperties.html R103
        entityCaseStatus.isOpenCorporatesOn = this.getIsOpenCorporatesOn(kind)

        entityCaseStatus.canScrap =
          hasCountryCaseType(kind, COUNTRY_CASE_TYPE.ScrapAutomated) ||
          hasCountryCaseType(kind, COUNTRY_CASE_TYPE.SearchOnly)

        entityCaseStatus.isManualCase = isManualCase

        // OpenCorporates=true
        if (hasCountryCaseType(kind, COUNTRY_CASE_TYPE.OpenCorporate)) {
          entityCaseStatus.isSwitchEnable = true
          entityCaseStatus.searchButtonVisible = !isManualCase
        } else if (hasCountryCaseType(kind, COUNTRY_CASE_TYPE.ScrapAutomated)) {
          // Automated jurisdictions
          entityCaseStatus.isSwitchEnable = true
          entityCaseStatus.searchButtonVisible = !isManualCase
        } else if (
          // Hybrid jurisdictions
          hasCountryCaseType(kind, COUNTRY_CASE_TYPE.HasSpecification) &&
          hasCountryCaseType(kind, COUNTRY_CASE_TYPE.SearchOnly)
        ) {
          entityCaseStatus.isSwitchEnable = true
          entityCaseStatus.searchButtonVisible = !isManualCase
        } else if (
          // Manual/RS yes jurisdictions
          hasCountryCaseType(kind, COUNTRY_CASE_TYPE.HasSpecification)
        ) {
          entityCaseStatus.isSwitchEnable = true
          entityCaseStatus.searchButtonVisible = false
        } else {
          // Manual jurisdictions
          entityCaseStatus.isSwitchEnable = false
          entityCaseStatus.searchButtonVisible = false
          entityCaseStatus.isForceOn = true
        }

        entityCaseStatus.isEmptyCase = this.checkIsEmptyCase({
          kind,
          manualCase: entityCaseStatus.isManualCase,
          entity: searchItem
        })
      } else {
        entityCaseStatus.searchButtonVisible = false
        entityCaseStatus.isForceOn = true
      }
    } else {
      entityCaseStatus.searchButtonVisible = false
      entityCaseStatus.isForceOn = false
    }

    const isCompanyTypeVisible = checkIsCompanyTypeVisible({
      kind,
      isManualOrUnregisteredEntity: is4UnregisteredEntity,
      actionType: isNew ? 'create' : 'update',
      platform: 'UploadPortal',
      stepId
    })
    entityCaseStatus.companyType = isCompanyTypeVisible
    entityCaseStatus.companyStatusVisible = isCompanyTypeVisible

    return entityCaseStatus
  }
}
