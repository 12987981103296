/**
 * Requirements url: https://knowyourcustomer.atlassian.net/wiki/x/AYByvg
 * Date: 2023-11-05
 * Author: Bruce Chi
 *
 * NOTE: If this file is updated, please sync the changes to the Workspace project's same file.
 *
 * This file contains utility functions related to the visibility of the company type field
 * based on various jurisdiction types and conditions. It includes an enumeration of
 * COUNTRY_CASE_TYPE which defines different types of jurisdiction cases and their corresponding
 * bitwise values. The file also provides a function to check if a specific flag is set in the
 * jurisdiction type using bitwise operations.
 *
 * From backend, the kind's values of the jurisdictions are:
 * public enum CountryCaseType
 * {
 *     NotSet = 0,
 *     SearchOnly = 1,
 *     ScrapAutomated = 2,
 *     RegistryConnected = SearchOnly | ScrapAutomated,
 *     HasSpecification = 4,
 *     OpenCorporate = 8    // ==> "Entity Search using OpenCorporates" ON
 * }
 * if kind & 8 === 8,
 *  it means 'Entity Search using OpenCorporates' is 'ON'
 *
 * https://kycl.slack.com/archives/D030K5PA3UG/p1650970813910889 by @stevewang1987 on 2022-04-26
 * if ((kind & 8) === 8) {
 *     if((kind & 2) === 2 || (kind & 1) === 1) {
 *        // 1. Quick Search first, if no results
 *        // 2. Advanced Search
 *     }
 *     else {
 *       // Quick Search only
 *     }
 * }
 * else if ((kind & 2) === 2)
 *     Automated
 * else if ((kind & 4) === 4 && (kind & 1) === 1)
 *     Hybrid
 * else if ((kind & 4) === 4)
 *     Manual/RS yes
 * else
 *     Manual
 */

/**
 * Enumeration of different types of jurisdiction cases and their corresponding bitwise values.
 *
 * @enum {number}
 * @readonly
 */
export enum COUNTRY_CASE_TYPE {
  /** Not set */
  NotSet = 0,
  /** Search only */
  SearchOnly = 1,
  /** Scrap automated */
  ScrapAutomated = 2,
  /** Registry connected (SearchOnly | ScrapAutomated) */
  RegistryConnected = SearchOnly | ScrapAutomated, // 11
  /** Has specification */
  HasSpecification = 4,
  /** OpenCorporate (Entity Search using OpenCorporates ON) */
  OpenCorporate = 8
}

/**
 * Checks if a specific COUNTRY_CASE_TYPE flag is set in the given kind value.
 *
 * @param {number} kind - The kind value representing jurisdiction type.
 * @param {COUNTRY_CASE_TYPE} key - The specific COUNTRY_CASE_TYPE flag to check.
 *
 * @returns {boolean} - Returns true if the flag is set, otherwise false.
 */
export const hasCountryCaseType = (kind: number, key: COUNTRY_CASE_TYPE): boolean => {
  return (kind & key) === key
}

/**
 * Determines the visibility of the company type field based on various parameters.
 *
 * @param {Object} params - The parameters for determining visibility.
 * @param {boolean} [params.isManualOrUnregisteredEntity=false] - Flag indicating if the entity is manual or
 *                                                                unregistered, default is false.
 * @param {number} [params.kind] - The kind value representing jurisdiction type.
 * @param {'create' | 'search' | 'update' | 'view'} [params.actionType='create'] - The action type, default is 'create'.
 * @param {'Workspace' | 'UploadPortal'} [params.platform='Workspace'] - The platform context, default is 'Workspace'.
 * @param {number} [params.stepId=0] - The step identifier, default is 0.
 *
 * @returns {boolean} - Returns true if the company type field should be visible, otherwise false.
 */
export const checkIsCompanyTypeVisible = ({
  isManualOrUnregisteredEntity = false,
  kind,
  actionType = 'create',
  platform = 'UploadPortal', // NOTE: This default value is for UploadPortal only
  stepId = 0
}: {
  isManualOrUnregisteredEntity?: boolean
  kind?: number
  actionType?: 'create' | 'search' | 'update' | 'view'
  platform?: 'Workspace' | 'UploadPortal'
  stepId?: number
}): boolean => {
  if (actionType === 'view') {
    return true
  }
  if (actionType === 'update') {
    if (platform === 'Workspace') {
      return true
    }
    if (stepId > 0) {
      return true
    }
    // The same as create
  }
  // actionType === 'create' | 'search'
  if (isManualOrUnregisteredEntity) {
    return true
  }
  if (typeof kind === 'undefined') {
    return false
  }

  if (hasCountryCaseType(kind, COUNTRY_CASE_TYPE.OpenCorporate)) {
    // OpenCorporate
    return false
  } else if (hasCountryCaseType(kind, COUNTRY_CASE_TYPE.ScrapAutomated)) {
    // Automated
    return false
  } else if (
    hasCountryCaseType(kind, COUNTRY_CASE_TYPE.HasSpecification) &&
    hasCountryCaseType(kind, COUNTRY_CASE_TYPE.SearchOnly)
  ) {
    // Hybrid
    return false
  } else if (hasCountryCaseType(kind, COUNTRY_CASE_TYPE.HasSpecification)) {
    // Manual/RS yes
    return true
  } else {
    // Manual
    return true
  }
}
