import { TEnvelopeStatus } from 'src/typings/types'
import { uploadPortalApiSlice } from './uploadPortalApiSlice'

const docuSignRequest = uploadPortalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    recipientView: builder.mutation<
      string,
      {
        envelopeId: string
        returnUrl: string
      }
    >({
      query: (data) => {
        return {
          url: `DocuSign/recipient-view`,
          method: 'POST',
          body: data
        }
      }
    }),
    envelopesStatus: builder.query<TEnvelopeStatus, string[]>({
      query: (data) => ({
        url: `DocuSign/envelope-status/batch`,
        method: 'POST',
        body: data
      }),
      providesTags: ['EnvelopesStatus']
    })
  }),
  overrideExisting: false
})

export default docuSignRequest
export const { useRecipientViewMutation, useEnvelopesStatusQuery } = docuSignRequest
